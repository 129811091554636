<template>
  <DataTable
      :loading="loading"
      :page="currentPage"
      :paginator="true"
      :paginatorTemplate="'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink'"
      :rows="perPage"
      :rowsPerPageOptions="pageSize"
      :value="items"
      responsiveLayout="scroll"
      stripedRows
      @page="selectTablePage($event)"
  >
    <template #empty>
      {{ _t('label_no_alarms_found') }}
    </template>
    <template #paginatorstart>
      <div class="p-inputgroup" style="padding-top:10px;">
        <p class="p-inputgroup-addon" style="padding: 5px; font-size: 1rem;">
          {{ _t('primefaces.paginator.aria.ROWS_PER_PAGE') }}
        </p>
        <Dropdown
            v-model="perPage"
            :options="pageSize"
            style="margin: 0px;"
        />
      </div>
    </template>
    <template #paginatorend>
    </template>
    <Column :header="_t('label_alarm_job_type')" field="alarmJobType">
      <template #body="{data}">
        <div class="p-d-inline-flex p-mt-2" style="width:2rem; ">
          <p>{{ _t(data.alarmJobType) }}</p>
        </div>
      </template>
    </Column>
    <Column :header="_t('label_coming')" field="alarmJobStartDate"></Column>
    <Column :header="_t('label_going')" field="alarmJobEndDate"></Column>
    <Column :header="_t('label_status')" field="alarmJobStatus">
      <template #body="{data}">
        <div class="p-d-inline-flex p-mt-2" style="width:2rem; ">
          <p>{{ _t(data.alarmJobStatus) }}</p>
        </div>
      </template>
    </Column>
  </DataTable>
</template>

<script>
export default {
  name: 'history-tab',
  props: {
    items: {
      type: Array,
      default: () => {
        return [];
      },
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pageSize: [5, 10, 20, 50],
      currentPage: 0,
      perPage: parseInt(localStorage.getItem('alarmTableRows') ?? 10),
    }
  },
  methods: {
    selectTablePage(event) {
      this.currentPage = event.page
      this.$emit('selectTablePage', event.page)
    }
  },
  watch: {
    perPage() {
      localStorage.setItem('alarmTableRows', this.perPage)
      this.currentPage = 0
    }
  }
}
</script>

<style scoped>

</style>
