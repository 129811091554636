<template>
  <transition name="slide-fade">
    <div v-if="selectedJobId" :class="`style p-md-7 p-lg-7 p-sm-12`">
      <div v-if="!isLoaded">
        <br>
        <Skeleton class="mb-2" width="5rem"></Skeleton>
        <br>
        <Skeleton height="4rem" width="10rem"></Skeleton>
        <div class="flex mb-3">
          <div>
            <Skeleton class="mb-2" width="10rem"></Skeleton>
            <Skeleton class="mb-2" width="5rem"></Skeleton>
            <br>
            <Skeleton height="2.5rem"></Skeleton>
          </div>
        </div>
        <br>
        <Skeleton class="mb-2" width="10rem"></Skeleton>
        <Skeleton class="mb-2" width="5rem"></Skeleton>
        <Skeleton class="mb-2" width="10rem"></Skeleton>
        <br>
        <div class="custom-skeleton p-4">
          <div class="flex mb-3">
            <div>
              <Skeleton class="mb-2" width="10rem"></Skeleton>
              <Skeleton class="mb-2" width="5rem"></Skeleton>
              <Skeleton height="2.5rem"></Skeleton>
            </div>
          </div>
          <br>
          <Skeleton height="150px" width="100%"></Skeleton>
          <div class="flex justify-content-center mt-3">
            <Skeleton height="2rem" width="4rem"></Skeleton>
            <Skeleton height="2rem" width="4rem"></Skeleton>
          </div>
          <br>
          <div class="flex justify-content-center mt-3">
            <Skeleton height="2rem" width="4rem"></Skeleton>
            <Skeleton height="2rem" width="4rem"></Skeleton>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="p-grid">
          <div class="p-p-3">
            <Button v-if="selectedAlarm && selectedAlarm.icon && selectedAlarm.icon.name"
                    v-tooltip.bottom="selectedAlarm.name ?? selectedAlarm.alarmName"
                    :style="`font-size: 2rem; background-color: ${selectedAlarm.icon.color}; border-color: ${selectedAlarm.icon.color}; vertical-align: top; padding: .7rem; border-radius: 5px;`"
                    class="p-mr-1">
              <i :class="selectedAlarm.icon.name"></i>
            </Button>
            <Button v-else
                    v-tooltip.bottom="selectedAlarm.name ?? selectedAlarm.alarmName"
                    :style="`font-size: 2rem; background-color: #989898; border-color: #989898; vertical-align: top; padding: .7rem; border-radius: 5px;`"
                    class="p-mr-1">
              <i class="mdi mdi-alert-outline"></i>
            </Button>
          </div>
          <div class="p-p-9">
            <h4 style="margin-top: 2rem;">
              <strong
                  class="truncate"
                  style="display:inline-block; vertical-align: center; max-width: 50rem; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                {{ selectedAlarm.name ?? selectedAlarm.alarmName }}
              </strong>
            </h4>
          </div>
        </div>
        <TabView v-model:activeIndex="activeTab" @tab-click="getHistoryAlarm()">
          <TabPanel :header="_t('menu.general')">
            <div class="p-grid">
              <div :class="`style p-col-3 `" style="vertical-align: middle; font-size: 10px;">
                <p class="p-p-1 p-m-0 truncate-text">{{ _t('label_alarm_id') }}</p>
                <p class="p-p-1 p-m-0 truncate-text">{{ _t('label_system_id') }}</p>
                <p v-if="selectedAlarm.mediaProcessingType" class="p-p-1 p-m-0 truncate-text">
                  {{ _t('Alarm_Processing_Configuration') }}
                </p>
                <p class="p-p-1 p-m-0 truncate-text">{{ _t('label_status') }}</p>
                <p class="p-p-1 p-m-0 truncate-text">{{ _t('label_organization') }}</p>
              </div>
              <div :class="`style p-col-4`" style="vertical-align: middle; font-size: 10px;">
                <Button class="p-button-link p-button-sm p-p-1 p-m-0" @click="toAlarmPoint(selectedAlarm.alarmId)">
                  <strong>
                    {{ selectedAlarm.alarmPointId }}
                  </strong>
                </Button>
                <p class="p-p-1 p-m-0 truncate-text">
                  <strong>
                    {{ selectedAlarm.systemId !== '[DEFAULT_EMPTY_SYS_ID_VALUE]' ? selectedAlarm.systemId : '' }}
                    {{
                      selectedAlarm.alarmSystemId !== '[DEFAULT_EMPTY_SYS_ID_VALUE]' ? selectedAlarm.alarmSystemId : ''
                    }}&nbsp;
                  </strong>
                </p>
                <p v-if="selectedAlarm.mediaProcessingType" class="p-p-1 p-m-0 truncate-text">
                  <strong>{{ selectedAlarm.mediaProcessingType }}&nbsp;</strong></p>
                <p class="p-p-1 p-m-0 truncate-text">
                  <Badge :style="'background-color:' + associateColor(selectedAlarm.jobStatusColor)"/>
                  <span
                      :style="'color:' + associateColor(selectedAlarm.jobStatusColor)"
                      class="p-m-2"
                  >
                    <strong>
                      {{ _t(selectedAlarm.alarmJobStatus) }}&nbsp;
                    </strong>
                  </span>
                  <Badge
                      :style="'background-color:' + associateColor()"
                  />
                  <span
                      :style="'color:' + associateColor()"
                      class="p-m-1"
                  >
                    <strong>
                      {{ _t(selectedAlarm.alarmPointStatus) }}&nbsp;
                    </strong>
                  </span>
                </p>
                <p class="p-p-1 p-m-0 truncate-text">
                  <OrganizationLink ref="orgLink" v-model="selectedAlarmPoint" modelType="ALARMPOINT"/>
                </p>
              </div>
              <div :class="`style p-col-2`" style="text-align: right;   vertical-align: middle;">
              </div>
              <div :class="`style p-col-3`" style="text-align: left;   vertical-align: middle;">
                <p class="p-p-1 p-m-0 truncate-text" style="font-size: 10px;">
                  <strong>{{ selectedAlarm.triggeredBy ? selectedAlarm.triggeredBy.login : '' }}</strong>
                </p>
                <p class="p-p-1 p-m-0" style="font-size: 10px;">
                  <i class="mdi mdi-play-outline" style="font-size: 14px;"></i>
                  {{ selectedAlarm.startDate }}
                </p>
                <p class="p-p-1 p-m-0" style="font-size: 10px;">
                  <i class="mdi mdi-stop-circle-outline" style="font-size: 14px;"/>
                  {{ selectedAlarm.doneDate }}
                </p>
                <p class="p-p-1 p-m-0" style="font-size: 10px;">
                  <i :class="selectedAlarm.endDate ?'mdi mdi-bookmark-outline' : 'mdi mdi-bookmark'"
                     :style="selectedAlarm.endDate  ? 'color: black;font-size: 14px;' : 'color: red;font-size: 14px;'"></i>
                  {{ selectedAlarm.endDate }}
                </p>
              </div>
            </div>
            <div style="padding: 5px;">
              <p style="margin: 0;">{{ _t('label_alarm_text') }}</p>
              <Textarea v-model="selectedAlarm.text" :autoResize="true" rows="5" style="width: 100%;"/>
            </div>
            <div>
              <hr>
              <span class="p-buttonset">
              <Button
                  :disabled="!selectedAlarm.canBeReTriggered && selectedAlarm.alarmPointStatus !== 'IDLE'"
                  class=""
                  style="background-color: rgb(226, 62, 87); border: none;"
                  @click="prepareTriggerAgain('K')"
              >
                {{ _t('label_Trigger') }}
              </Button>
              <Button
                  :disabled="selectedAlarm.endDate !== null"
                  style="background-color: gray; border: none;"
                  @click="prepareTriggerAgain('G')"
              >
                {{ _t('label_going') }}
              </Button>
              <Button
                  :disabled="!selectedAlarm.quitable"
                  class="p-button-warning"
                  style="border: none;"
                  @click="terminateAlarm(selectedAlarm.alarmId)"
              >
                {{ _t('label_Terminate') }}
              </Button>
            </span>
            </div>
            <div v-if="selectedAlarm.participantStatusesSummary">
              <hr>
              <p style="margin: 0;">{{ _t('label_Acknowledgements_detail') }}</p>
              <div v-for="item in selectedAlarm.participantStatusesSummary" :key="item.status">
                <p>
                  <Badge
                      :style="{
                    'background-color': associateColor(item.color),
                    color: associateColor(item.color) === 'transparent' ? 'gray' : 'white'
                  }"
                      :value="item.count"
                      style="border: 1px solid gray; border-radius: 10px"
                  />
                  {{ _t('label_participants') }} {{ _t(item.status) }}
                </p>
              </div>
            </div>
            <div v-if="selectedCallGroupJobs.length > 0">
              <hr>
              <p style="margin: 0;">{{ _t('label_participants') }}</p>
              <TreeTable v-model:selectionKeys="selectedCallGroupJobKeys"
                         :value="selectedCallGroupJobs"
                         selectionMode="checkbox"
                         style="font-size: 10px;"
              >
                <Column :expander="true" headerStyle=""></Column>
                <Column :header="_t('label_object_name')" field="name" style="width: 10rem;"></Column>
                <Column :header="_t('label_started')" field="startDate" headerStyle="width: 6rem;">
                </Column>
                <Column :header="_t('label_ends')" field="endDate" headerStyle="width: 6rem;"></Column>
                <Column :header="_t('label_status')" field="status" style="width: 10rem;">
                  <template #body="slotProps">
                    <Badge
                        v-if="slotProps.node.data.status"
                        v-tooltip="_t(slotProps.node.data.status)" :style="' margin:2px; width:100px;' +
                             'background-color: ' + associateColor(slotProps.node.data.color)"
                        :value="_t(slotProps.node.data.status)"
                        class="p-text-truncate"
                        style="font-size: 0.75rem !important; margin-bottom: 0px; margin-top: 0px;"/>
                  </template>
                </Column>
                <Column :header="_t('label_call_media')" field="message" style="max-width: 10rem;">
                  <template #body="slotProps">
                    <p v-tooltip.left="slotProps.node.data.message" class="p-text-truncate">
                      {{ slotProps.node.data.message }}</p>
                  </template>
                </Column>
                <Column :header="_t('label_result')" field="result">
                  <template #body="slotProps">
                    <p v-tooltip.bottom="_t(slotProps.node.data.result ? '_'+slotProps.node.data.result : '' )"
                       class="p-text-truncate">
                      {{ _t(slotProps.node.data.result ? '_' + slotProps.node.data.result : '') }}
                    </p>
                  </template>
                </Column>
                <Column :header="_t('label_call_number')" field="callingNumber">
                  <template #body="slotProps">
                    <p v-tooltip.left="slotProps.node.data.callingNumber" class="p-text-truncate">
                      {{ slotProps.node.data.callingNumber }}</p>
                  </template>
                </Column>
              </TreeTable>
            </div>
          </TabPanel>
          <TabPanel :header="_t('label_info')">
            <p>{{ _t('label.Description') }}</p>
            <Textarea v-model="selectedAlarm.description" :autoResize="true" rows="5" style="width: 100%;"/>

            <p>{{ _t('label_comment') }}</p>
            <Textarea v-model="selectedAlarm.alarmJobComment" :autoResize="true" rows="5" style="width: 100%;"/>
          </TabPanel>
          <TabPanel :header="_t('INSTRUCTION_FILES')">
            <div v-if="selectedAlarm.instructionFiles && selectedAlarm.instructionFiles.length > 0"
                 class="p-grid p-nogutter p-mb-4">
              <div v-for="(instruction, index) in selectedAlarm.instructionFiles"
                   :key="index"
                   class="p-col-12"
              >
                <Button :label="instruction.split('/').pop()"
                        class="p-button-link p-button-text p-button-success"
                        @click="downloadInstruction(instruction)"
                />
              </div>
            </div>
            <p v-else>
              <b>
                {{ _t('label_intruction_file_missed') }}
              </b>
            </p>
          </TabPanel>
          <TabPanel :header="_t('label_history')">
            <HistoryTab
                :items="history.items"
                :loading="history.loading"
                @select-table-page="historyTab.currentPage = $event"
            ></HistoryTab>
          </TabPanel>
        </TabView>
      </div>
    </div>
  </transition>
  <Dialog v-model:visible="triggerAgainDlg" :closable="false" :modal="true" style="min-width: 650px">
    <template #header>
      <div style="width: 100%">
        <h3 :style="{
            backgroundColor: preparedTriggerBody.eventType === 'G' ? 'gray' : 'rgb(226, 62, 87)',
            color: '#fff',
            textAlign: 'center'
          }" class="p-m-0 p-p-3 p-d-block p-text-uppercase p-text-bold"
            style="width: 100%"
        >
          <template v-if="preparedTriggerBody.eventType === 'G'">{{ _t('label_going') }}</template>
          <template v-else>{{ _t('label_triggering') }}</template>
        </h3>
        <h3 :style="{
            backgroundColor: (selectedAlarm.icon?.color ?? 'gray'),
            color: '#fff'
          }" class="p-m-0 p-p-3 p-d-block"
            style="width: 100%"
        >
          <i :class="[selectedAlarm?.icon?.name]"></i>
          {{ selectedAlarm.name }}
        </h3>
      </div>
    </template>
    <div class="p-d-flex p-flex-column p-mt-4" style="width: 100%">
      <div class="p-d-inline-flex p-flex-row p-justify-between p-mb-4" style="width: 100%">
        <div class="p-d-inline-flex p-jc-center">
          <Dropdown
              v-model="selectedAlarm.priority"
              :options="[0,1,2,3,4,5,6,7,8,9,10]"
              :placeholder="_t('label_priority')"
              style="width:100%; height: 2rem;"
          />
        </div>
        <div class="p-d-inline-flex p-jc-center">
          <Dropdown
              v-model="selectedVar"
              :options="textVars"
              :placeholder="_t('label_variables')"
              @change="preparedTriggerBody.text += selectedVar; selectedVar = null"
          />
        </div>
      </div>
      <div class="p-d-inline-flex p-flex-row" style="width: 100%">
        <Textarea
            v-model="preparedTriggerBody.text"
            :placeholder="_t('label_message')"
            :rows="15"
            style="width:100%;"
        />
      </div>
    </div>
    <template #footer>
      <Button :label="_t('Cancel')" class="p-button-danger" icon="pi pi-times"
              @click="triggerAgainDlg = false; preparedTriggerBody.text = ''"
      />
      <Button :label="_t('Save')" autofocus class="p-button-success" icon="pi pi-check" @click="triggerAgain"/>
    </template>
  </Dialog>
</template>

<script>

import {getHistoryDetails, prepareTriggerAgainAlarm, terminateAlarm, triggerAgainAlarm} from "@/api/alarmTable";
import {associateColor} from "@/service/helper/styleHelper";
import HistoryTab from '@/views/alarm_table/history-tab';
import {getAlarm, getHistoryByAlarm} from "../../../api/alarmTable";
import Tooltip from 'primevue/tooltip';
import OrganizationLink from '../../../components/ixarma/OrganizationLink'
import {getAlarmPoint} from '@/api/alarmPoint'

export default {
  name: "item",
  components: {
    HistoryTab: HistoryTab,
    OrganizationLink
  },
  directives: {
    'tooltip': Tooltip
  },
  props: {
    selectedJobId: {
      type: [String, null],
      default() {
        return null
      }
    },
    alarmType: {
      type: [Number, String],
      default() {
        return 0
      }
    }
  },
  data() {
    return {
      selectedAlarm: {
        alarmPointName: '',
        alarmJobEndDate: '',
        participantStatusesSummary: null,
        jobStatusColor: '',
        callGroupJobs: []
      },
      selectedCallGroupJobs: [],
      selectedCallGroupJobKeys: [],
      selectedAlarmPoint: null,
      triggerAgainDlg: false,
      preparedTriggerBody: null,
      selectedAlarmIntervalId: null,
      activeTab: 0,
      history: {
        alarmPointId: "",
        items: [],
        totalItems: 0,
        pagesCount: 0,
        loading: false,
      },
      historyTab: {
        currentPage: 0,
        sortField: '',
        textFilter: '',
        pageSize: parseInt(localStorage.getItem('alarmTableRows') ?? 10),
        columnsFilter: `alarmPointId=`,
        organiztionFilter: null,
      },
      selectedVar: null,
      textVars: [
        '<original>', '<K>', '<G>', '<family>', '<callerid>'
      ],
      isLoaded: false
    }
  },
  watch: {
    selectedJobId() {
      this.activeTab = 0;
      this.isLoaded = false
      this.runGetAlarm()
    }
  },
  beforeUnmount() {
    clearInterval(parseInt(this.selectedAlarmIntervalId))
  },
  methods: {
    toAlarmPoint(id) {
      let route = this.selectedAlarmPoint.deleted ? '/deleted-alarm-points/' : '/alarm-points/'
      this.$router.push(`${route}${id}`)
    },
    runGetAlarm() {
      this.processGetAlarm()
      if (!this.selectedAlarmIntervalId) {
        this.selectedAlarmIntervalId = setInterval(() => {
          this.processGetAlarm()
        }, 5000)
      }
    },
    processGetAlarm() {
      if (this.selectedJobId) {
        if (this.alarmType === 0) {
          getAlarm(this.selectedJobId).then((response) => {
            if (response.data) {
              this.selectedAlarm = response.data
              this.prettifyAlarmAttributes()
              if (this.selectedAlarm.callGroupJobs) {
                this.selectedCallGroupJobs = []
                this.mapCallGroupJobs()
              }
              getAlarmPoint(this.selectedAlarm.alarmId).then((response) => {
                this.selectedAlarmPoint = response.data
              });
              this.isLoaded = true
            } else {
              this.$emit('update:selectedJobId', null);
            }

          })
        } else {
          getHistoryDetails(this.selectedJobId).then((response) => {
            if (response.data) {
              this.selectedAlarm = response.data
              this.prettifyAlarmAttributes()
              getAlarmPoint(this.selectedAlarm.alarmId).then((response) => {
                this.selectedAlarmPoint = response.data
              });
              if (this.selectedAlarm.callGroupJobs) {
                this.selectedCallGroupJobs = []
                this.mapCallGroupJobs()
                this.isLoaded = true
              }
            } else {
              this.$emit('update:selectedJobId', null);
            }
          })
        }
      } else {
        this.isLoaded = false
        this.selectedAlarm = {
          alarmPointName: '',
          alarmJobEndDate: '',
          participantStatusesSummary: null,
          jobStatusColor: '',
          callGroupJobs: []
        };
      }
    },
    prettifyAlarmAttributes() {
      if (this.selectedAlarm) {
        try {
          this.selectedAlarm.alarmJobComment = this._t(this.selectedAlarm.alarmJobComment)
          this.selectedAlarm.description = this._t(this.selectedAlarm.description)
          this.selectedAlarm.icon = JSON.parse(this.selectedAlarm.icon)
        } catch (e) {
          // noting to do
        }
      }
    },
    prepareTriggerAgain(status = null) {
      let type = 'history'
      if (this.alarmType === 0) {
        type = 'alarmtable'
      }
      prepareTriggerAgainAlarm(this.selectedJobId, type).then((response) => {
        this.preparedTriggerBody = response.data

        this.triggerAgainDlg = true
        if (status !== null) {
          this.preparedTriggerBody.eventType = status
        }
      })
    },
    triggerAgain() {
      triggerAgainAlarm(this.preparedTriggerBody).then(() => {
        if (this.selectedAlarm.endDate) {
          this.$emit('update:selectedJobId', null);
        }
        this.triggerAgainDlg = false
        this.$emit('clearComponent')
      });
    },
    terminateAlarm(alarmId) {
      terminateAlarm(alarmId).then(() => {
        this.processGetAlarm()
      })
    },
    mapCallGroupJobs() {
      this.selectedAlarm.callGroupJobs.map((item, index) => {
        const container = {data: {}, children: []};
        container.data = item
        container.leaf = false
        container.key = index;
        container.data.id = 'Call Group: ' + container.data.id
        // eslint-disable-next-line no-unused-vars
        for (const [key, participant] of container.data.participantJobs.entries()) {
          const mediaJobs = []
          // eslint-disable-next-line no-unused-vars
          for (const [mediaJobKey, mediaJob] of participant.configuredMediaJobs.entries()) {
            const mediaJobData = {
              key: mediaJob.id,
              data: {
                id: 'Media Job: ' + mediaJob.id,
                name: mediaJob.callingDeviceType,
                startDate: mediaJob.startDate,
                endDate: mediaJob.endDate,
                status: mediaJob.configuredMediaJobStatus,
                message: mediaJob.message,
                result: mediaJob.result,
                color: mediaJob.statusColor ?? null,
                callingNumber: mediaJob.callingNumber,
              }
            }
            mediaJobs.push(mediaJobData)
          }
          const participantData = {
            key: participant.id,
            data: {
              id: 'Participant: ' + participant.id,
              name: participant.name + ' ' + participant.surname,
              status: participant.participantJobStatus,
              color: participant.jobStatusColor ?? null,
            },
            children: mediaJobs
          }
          if (!(this.selectedAlarm.callGroupJobs.length === 1 && container.data.name === null)) {
            container.children.push(participantData)
          } else {
            this.selectedCallGroupJobs.push(participantData)
          }
        }
        if (!(this.selectedAlarm.callGroupJobs.length === 1 && container.data.name === null)) {
          this.selectedCallGroupJobs.push(container)
        }
        // return container;
      })
    },
    associateColor,
    getHistoryAlarm() {
      if (this.activeTab === 3) {
        this.historyTab.columnsFilter = {
          alarmPointId: this.selectedAlarm.alarmPointId,
        }
        this.history.loading = true
        getHistoryByAlarm(
            this.selectedAlarm.alarmId,
            this.historyTab.currentPage,
            this.historyTab.sortField,
            this.historyTab.textFilter,
            this.historyTab.pageSize,
            this.historyTab.columnsFilter,
            this.historyTab.organiztionFilter,
            {
              hours: 999999999,
              option: 'HOURS_DURATION'
            },
        ).then(response => {
          this.history.items = response.data.list ?? [];
          this.history.totalItems = response.data.count;
          this.history.pagesCount = response.data.pagesCount;
        })
            .finally(() => {
              this.history.loading = false;
            })
      }
    }
  }
}
</script>

<style scoped>
.truncate-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
